import * as React from "react"
import Navbar from "../components/Navbar";
import Helmet from "react-helmet";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import {StaticImage} from "gatsby-plugin-image";

// markup
const Contact = () => {
    return (
        <>
            <Helmet>
                <body className="page-contact" />
            </Helmet>

            <Navbar/>

            <main>
                <div className="hero-wrapper">
                    <Hero title="Contact" width="50"/>
                    <StaticImage src="../images/pexels-alex-andrews-821754.jpg" alt="Contact header" className="hero-image" />
                </div>

                <article>
                    <div className="content">
                        <div className="cols">

                            {/*<div className="col-xs-100 col-sm-50 col-md-25">*/}
                            {/*    <section className="padding-right-2em">*/}
                            {/*    /!* TODO a Better way to start at 50% or 25%*!/*/}
                            {/*    </section>*/}
                            {/*</div>*/}

                            <div className="col-xs-100 col-sm-50 col-md-75">
                                <section className="text-based">
                                    <p>
                                        Bel of mail me voor meer informatie over een uitbreiding of als je wilt weten hoe dit voor jouw organisatie geactiveerd kan worden.
                                    </p>

                                    <p>
                                        Of neem contact op om te weten wat ik voor jou/jullie kan betekenen:
                                    </p>


                                    <ul className="check color-green">
                                        <li><a href="tel:+31614220799">06 1422 0799</a></li>
                                        <li><a href="mailto:info@meneerjansen-ict.nl">info@meneerjansen-ict.nl</a></li>
                                    </ul>
                                </section>
                            </div>
                        </div>


                        <div className="cols">

                            <div className="col-xs-100 col-sm-50 col-md-25">
                                <section className="padding-right-2em">

                                </section>
                            </div>


                            {/*<div className="col-xs-100 col-sm-50 col-md-75">*/}


                            {/*    <section className="text-based">*/}
                            {/*        Of vul dit formulier in en dan neem ik contact met je op:*/}
                            {/*    </section>*/}


                            {/*    <section className="text-based">*/}
                            {/*        <form method="mailto:info@meneerjansen-ict.nl" method="GET" name="Contact formulier">*/}


                            {/*            <div className="label">*/}
                            {/*                <label htmlFor="form-field-name">Naam</label>*/}
                            {/*            </div>*/}
                            {/*            <div className="input">*/}
                            {/*                <input type="text" name="form_fields[name]" id="form-field-name" placeholder="Naam"/>*/}
                            {/*            </div>*/}

                            {/*            <div className="label">*/}
                            {/*                <label htmlFor="form-field-email">Telefoon of email </label>*/}
                            {/*            </div>*/}
                            {/*            <div className="input">*/}
                            {/*                <input type="text" name="form_fields[email]" id="form-field-email" placeholder="Telefoonnummer of email adres"/>*/}
                            {/*            </div>*/}

                            {/*            <div className="label">*/}
                            {/*                <label htmlFor="form-field-message">Bericht</label>*/}
                            {/*            </div>*/}
                            {/*            <div className="input">*/}
                            {/*                <textarea name="form_fields[message]" id="form-field-message" rows="4" placeholder="Bericht">*/}
                            {/*                </textarea>*/}
                            {/*            </div>*/}

                            {/*            <p className="ButtonContainer">*/}
                            {/*                <button type="submit">Versturen</button>*/}
                            {/*            </p>*/}
                            {/*        </form>*/}
                            {/*    </section>*/}

                            {/*</div>*/}
                        </div>



                    </div>
                </article>

            </main>

            <Footer />
        </>
    )
}

export default Contact;
